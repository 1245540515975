import React, { useMemo, useState } from 'react';
import {
    Text,
    Link,
} from '@chakra-ui/react'
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import Dropbox from '@uppy/dropbox';
import GoogleDrive from '@uppy/google-drive';
import Tus from '@uppy/tus';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { useToken } from '@/src/store/token-store'
import { useUser } from '@/src/store/user-store';
import { useChannelDetailsQuery } from '@/src/queries/my-channel/channel-details';

const UppyUploader = ({ handleFileChange, resetUpload, onClose, setErrorData, onErrorOpen }: { handleFileChange: (file: File | null, uppyUploadUrl: string) => void, resetUpload: Function, onClose: Function, setErrorData: Function, onErrorOpen: Function }) => {
    const { accessToken } = useToken();
    const [currentStatus, setCurrentStatus] = useState<string>("");
    const { user } = useUser()
    const { data: channelData } = useChannelDetailsQuery(
        user?.channelShareName || ''
    )
    const uppy = useMemo(() => {
        const uppyInstance = new Uppy({
            debug: true,
            autoProceed: false,
            restrictions: {
                maxNumberOfFiles: 1,
                allowedFileTypes: ['video/*'],
            },
        })
            .use(Dropbox, {
                companionUrl: process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev' ? 'https://upload.lykstage.com:9093' : 'https://uploader.lykstage.com:9093',
            })
            .use(GoogleDrive, {
                companionUrl: process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev' ? 'https://upload.lykstage.com:9093' : 'https://uploader.lykstage.com:9093',
            })
            .use(Tus, {
                endpoint: process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev' ? 'https://upload.lykstage.com/files' : 'https://uploader.lykstage.com/files',
                headers: {
                    Authorization: 'Bearer ' + accessToken,
                },
            });

        uppyInstance.on('file-added', (file) => {
            setCurrentStatus("");
          
            let fileSize: number = file.size || 0;
            if (Number(channelData?.uploadVerify)) {
                const MAX_FILE_SIZE = 30 * 1024 * 1024 * 1024;
                if (fileSize > MAX_FILE_SIZE) {
                    setErrorData(
                        {
                            header: 'Upload Limitation',
                            description: 'You are eligible to upload videos up to 12 hours long and 30 GB in size.',
                            icon: '/upload/upload-size-error.svg', btn: "Got it"
                        }
                    )
                    onErrorOpen()
                    uppy.removeFile(file.id)
                    return
                }
            } else if (Number(channelData?.uploadVerify) === 0) {
                const MAX_FILE_SIZE = 10 * 1024 * 1024 * 1024 ;
                if (fileSize > MAX_FILE_SIZE) {
                    setErrorData(
                        {
                            header: 'Upload Limitation',
                            description: 'You are eligible to upload videos up to 30 minutes long and 10 GB in size.',
                            icon: '/upload/upload-size-error.svg', btn: "Got it"
                        }
                    )
                    onErrorOpen()
                    uppy.removeFile(file.id)
                    return
                }
            }  });
        uppyInstance.on('upload-progress', (file, progress) => {
            setCurrentStatus("upload-progress");
        });

        uppyInstance.on('upload-error', (file, error) => {
            setCurrentStatus("upload-error");
        });
        uppyInstance.on('file-removed', (file) => {
            setCurrentStatus("");
        });
        uppyInstance.on('complete', (result) => {
            if (result.successful && result.successful.length > 0) {
                handleFileChange(null, result.successful[0]?.uploadURL || '');
            }
        });


        return uppyInstance;
    }, [accessToken]);

    return (
        <>
            <Dashboard
                id="dashboard"
                key="dashboard"
                uppy={uppy}
                plugins={['Dropbox', 'GoogleDrive']}
                showProgressDetails
                height={340}
                width={"100%"}
                showLinkToFileUpload={false}
                disableLocalFiles={true}
                // @ts-ignore 
                locale={{
                    strings: {
                        importFiles: 'Select video file from below options',
                        poweredBy: '',
                    },
                }}
            />
            {currentStatus === "upload-progress" ? (
                <>
                    <Text
                        color={'#656565'}
                        fontSize={14}
                        fontWeight={400}
                        align={'center'}
                        mb={5}
                    >
                        Uploads from Dropbox  and Google Drive will continue in the background - you can click off this screen once your video starts uploading! Your video will appear under drafts in<Link href={'/c/' + user?.channelShareName}>
                            <Text color={'lyk-green'} display={'inline'} textDecoration={'none'} marginLeft={'2px'}>My Channel</Text>
                        </Link> .
                    </Text>
                    <button className="rounded-lg bg-[#24BFA3] p-2 text-[16px] text-white duration-300" onClick={() => { resetUpload(), onClose() }}>
                        Save & continue
                    </button>
                </>
            ) : (
                <>
                    <Text
                        align={'center'}
                        mb={2}
                        opacity={0.7}
                        fontWeight={500}
                        fontSize={16}
                    >{
                            Number(channelData?.uploadVerify) ?
                                <>You are eligible to upload videos up to <b>12 hours long</b>  and <b>30 GB in size.</b></>
                                :
                                <>You are eligible to upload videos up to <b>30 minutes long</b>  and <b>10 GB in size.</b></>
                        }
                    </Text>
                    <Text
                        color={'lyk-green'}
                        fontSize={16}
                        fontWeight={600}
                        align={'center'}
                        mb={5}
                    >
                        Videos uploaded must be longer than 3 minutes to start earning from watch time.
                    </Text>
                    <Text fontSize={14} fontWeight={400} align={'center'}>
                        By submitting your content to LYKSTAGE, you agree to our{' '}
                        <Link
                            color={'lyk-green'}
                            fontWeight={600}
                            target="_blank"
                            href="/termsofservice"
                            onClick={(e) => e.stopPropagation()}
                        >
                            Terms of Service
                        </Link>{' '}
                        ,{' '}
                        <Link
                            color={'lyk-green'}
                            fontWeight={600}
                            target="_blank"
                            href="/privacypolicy"
                            onClick={(e) => e.stopPropagation()}
                        >
                            Privacy Policy
                        </Link>{' '}
                        , and{' '}
                        <Link
                            color={'lyk-green'}
                            fontWeight={600}
                            target="_blank"
                            href="/community"
                            onClick={(e) => e.stopPropagation()}
                        >
                            Community Guidelines
                        </Link>
                        . You also confirm that your content does not infringe on any copyright or privacy rights.
                    </Text>
                </>
            )}
        </>
    );
};

export default UppyUploader;