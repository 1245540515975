import { makeApiRequest } from '@/src/lib/api-request'
import { useQuery } from '@tanstack/react-query'

export interface Category {
  categoryId: string
  categoryName: string
}

export const useCategoriesQuery = () => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      const response = await makeApiRequest({
        method: 'GET',
        url: '/Landing/getVideoCategory',
        isAuthenticated: false,
        fallbackError: 'Error while fetching categories',
        data: {},
        isV3Api:true
      })

      const data: Category[] = response.response.categoryList
      return data as Category[]
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}
