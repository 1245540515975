import {
  Text,
  Button,
  Image,
  ModalBody,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Flex,
  Icon,
  SimpleGrid,
  Skeleton,
  ModalFooter,
  FormErrorMessage,
  Checkbox,
  Link,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react'
import React, { useState, useRef, useEffect } from 'react'
import { SelectButtonGroup } from './togglebtn'
import { ProgressBar } from './progressbar'
import { Clock, Lock, Plus, Star, UploadCloudIcon, X } from 'lucide-react'
import ControlledSelect from '../account/select-multi'
import { Language, useLanguagesQuery } from '@/src/queries/auth/languages-query'
import {
  Category,
  useCategoriesQuery,
} from '@/src/queries/upload/categories-query'
import {
  useForm,
  FormProvider,
  FieldValues,
  Control,
  useController,
} from 'react-hook-form'
import { FileUploader } from 'react-drag-drop-files'
import { useSaveVideoDetailsMutation } from '@/src/mutations/upload/save-video-mutation'
import { toast } from 'sonner'
import { usePublishVideoMutation } from '@/src/mutations/upload/publish-video-mutation'
import { FinalDataInterface } from './videouploadflow'
import { useRouter } from 'next/navigation'
import { DraftData } from '../ui/my-channel/my-channel-drafts'
import PreviewPlayerWrapper from './preview-player-wrapper'
import { useUser } from '@/src/store/user-store'
import { useParams } from 'next/navigation'
import { useQueryClient } from '@tanstack/react-query'
import { useGenerateSubtitlesMutation } from '@/src/mutations/subtitles/create-subtitles'
import { format } from 'date-fns'
const fileTypes = ['jpg', 'jpeg', 'png']
const inputStyles = {
  size: {
    base: 'md',
  },
  rounded: 'md',
  border: '1px solid #E5E5E5',
  px: {
    base: '3',
    md: '4',
  },
  fontSize: {
    base: 'sm',
    md: 'md',
  },
  mt: 1.5,
}

const labelStyles = {
  fontSize: {
    base: 'sm',
    md: 'md',
  },
  fontWeight: '500',
  marginBottom: '1px',
  color: '#3E3E3E',
  opacity: 0.8,
}

function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.onerror = (error) => {
      reject('Error: ' + error)
    }
  })
}

const VideoType = ({
  value,
  handleChange,
}: {
  value: string
  handleChange: (value: string) => void
}) => {
  return (
    <SelectButtonGroup onChange={handleChange} value={value}>
      <Button
        _hover={{ bg: 'lyk-green', color: 'white' }}
        color={'white'}
        bg={'lyk-green'}
        value={'free'}
        fontSize={14}
        fontWeight={500}
        rounded={'full'}
        px={5}
      >
        Free
      </Button>
      <Button
        _hover={{ bg: 'lyk-green', color: 'white' }}
        color={'white'}
        bg={'lyk-green'}
        value={'premium'}
        fontSize={14}
        fontWeight={500}
        rounded={'full'}
        px={5}
        leftIcon={<Icon as={Star} />}
      >
        Premium
      </Button>
      <Button
        _hover={{ bg: 'lyk-green', color: 'white' }}
        color={'white'}
        bg={'lyk-green'}
        value={'passwordprotected'}
        fontSize={14}
        fontWeight={500}
        rounded={'full'}
        px={5}
        leftIcon={<Icon as={Lock} />}
      >
        Password Protected
      </Button>
    </SelectButtonGroup>
  )
}

const VisibilityType = ({
  value,
  handleChange,
}: {
  value: string
  handleChange: (value: string) => void
}) => {
  return (
    <SelectButtonGroup onChange={handleChange} value={value}>
      <Button
        _hover={{ bg: 'lyk-green', color: 'white' }}
        color={'white'}
        bg={'lyk-green'}
        value={'0'}
        fontSize={14}
        fontWeight={500}
        rounded={'full'}
        px={5}
      >
        Public
      </Button>
      <Button
        _hover={{ bg: 'lyk-green', color: 'white' }}
        color={'white'}
        bg={'lyk-green'}
        value={'1'}
        fontSize={14}
        fontWeight={500}
        rounded={'full'}
        px={5}
        leftIcon={<Icon as={Star} />}
      >
        Private
      </Button>
      <Button
        _hover={{ bg: 'lyk-green', color: 'white' }}
        color={'white'}
        bg={'lyk-green'}
        value={'2'}
        fontSize={14}
        fontWeight={500}
        rounded={'full'}
        px={5}
        leftIcon={<Icon as={Lock} />}
      >
        Protected
      </Button>
    </SelectButtonGroup>
  )
}

const Scheduling = ({
  control,
  name,
  id,
  label,
}: {
  control: Control<FieldValues> | undefined
  name: string
  id: string
  label: string
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  })
  return (
    <FormControl isInvalid={!!error} id={id} isRequired={true}>
      <FormLabel {...labelStyles} htmlFor={name} mb={2}>
        {label}
      </FormLabel>
      <SelectButtonGroup onChange={onChange} value={value}>
        <Button
          _hover={{ bg: 'lyk-green', color: 'white' }}
          color={'white'}
          bg={'lyk-green'}
          value={'publish_now'}
          fontSize={14}
          fontWeight={500}
          rounded={'full'}
          px={5}
          leftIcon={<Icon as={UploadCloudIcon} />}
        >
          Publish Now
        </Button>
        <Button
          _hover={{ bg: 'lyk-green', color: 'white' }}
          color={'white'}
          bg={'lyk-green'}
          value={'schedule'}
          fontSize={14}
          fontWeight={500}
          rounded={'full'}
          px={5}
          leftIcon={<Icon as={Clock} />}
          m={0}
        >
          Schedule Later
        </Button>
      </SelectButtonGroup>
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  )
}

const Thumbnails = ({
  generatedThumbnails,
  setThumbnail,
  selectedThumbnail,
  uploadedThumbnail,
  setUploadedThumbnail,
  setUploadedThumbnailFile,
  uploadedThumbnailURL,
  setUploadedThumbURL,
}: {
  setThumbnail: (thumb: number) => void
  generatedThumbnails: Array<string>
  selectedThumbnail: number
  uploadedThumbnail: string
  setUploadedThumbnail: (thumb: string) => void
  setUploadedThumbnailFile: (thumb: File | null) => void
  uploadedThumbnailURL: string
  setUploadedThumbURL: (thumb: string) => void
}) => {
  return (
    <Box>
      <Text {...labelStyles}>Thumbnail</Text>
      <SimpleGrid columns={[2, 2, 4]} spacing={4} mt={2}>
        {uploadedThumbnail || uploadedThumbnailURL ? (
          <Box
            cursor={'pointer'}
            width={'100%'}
            aspectRatio={16 / 9}
            rounded={'md'}
            border={selectedThumbnail == 4 ? 'solid' : 'dashed'}
            borderWidth={3}
            borderColor={selectedThumbnail == 4 ? 'lyk-green' : '#eaeaea'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            onClick={() => {
              setThumbnail(4)
            }}
            pos="relative"
            bg="white"
          >
            {
              <Box
                pos="absolute"
                bg="lyk-red"
                rounded="100%"
                p={2}
                opacity={0.7}
                _hover={{
                  opacity: 1,
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  setThumbnail(-1)
                  setUploadedThumbnail('')
                  setUploadedThumbnailFile(null)
                  setUploadedThumbURL('')
                }}
              >
                <X size={20} color={'white'} />
              </Box>
            }
            <Image
              src={uploadedThumbnail || uploadedThumbnailURL}
              width={'100%'}
              aspectRatio={16 / 9}
              alt="Thumbnail"
              objectFit={'cover'}
            />
          </Box>
        ) : (
          <FileUploader
            handleChange={async (selectedFile: File) => {
              const fileSize = selectedFile.size / 1024 / 1024
              if (fileSize > 2) {
                toast.error('File size should not exceed 2MB')
                return
              }
              const base = await getBase64(selectedFile)
              setUploadedThumbnail(base)
              setUploadedThumbnailFile(selectedFile)
              setUploadedThumbURL('')
              setThumbnail(4)
            }}
            name="file"
            types={fileTypes}
            classes="dragdrop"
          >
            <Box
              cursor={'pointer'}
              width={'100%'}
              aspectRatio={16 / 9}
              rounded={'md'}
              border={selectedThumbnail == 4 ? 'solid' : 'dashed'}
              borderWidth={3}
              borderColor={selectedThumbnail == 4 ? 'lyk-green' : '#eaeaea'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              overflow={'hidden'}
            >
              <Flex alignItems={'center'}>
                <Plus
                  size={18}
                  color={'#3E3E3E'}
                  opacity={0.8}
                  className="mr-1"
                />
                <Text fontSize={12} opacity={0.8} color={'#3E3E3E'}>
                  Upload thumbnail
                </Text>
              </Flex>
            </Box>
          </FileUploader>
        )}

        {generatedThumbnails.length > 0 ? (
          generatedThumbnails.map((img, index) => {
            return (
              <Box
                key={index}
                onClick={() => {
                  setThumbnail(index)
                }}
                cursor={'pointer'}
              >
                <Image
                  src={img}
                  width={'100%'}
                  aspectRatio={16 / 9}
                  rounded={'md'}
                  border={'solid'}
                  borderColor={'lyk-green'}
                  borderWidth={index == selectedThumbnail ? 3 : 0}
                  bg={'#eaeaea'}
                  alt="Thumbnail"
                  objectFit={'cover'}
                />
              </Box>
            )
          })
        ) : (
          <>
            <Skeleton
              width={'100%'}
              aspectRatio={16 / 9}
              rounded={'md'}
              objectFit={'cover'}
            />

            <Skeleton
              width={'100%'}
              aspectRatio={16 / 9}
              rounded={'md'}
              objectFit={'cover'}
            />

            <Skeleton
              width={'100%'}
              aspectRatio={16 / 9}
              rounded={'md'}
              objectFit={'cover'}
            />
          </>
        )}
      </SimpleGrid>
    </Box>
  )
}

const findLanguagesByIds = (ids: string, languages?: Language[]) => {
  if (!ids) return []
  if (!languages) return []
  const languageIds = ids.split(',')
  const selectedLanguages: SelectField[] = []
  languageIds.forEach((id) => {
    const language = languages.find((lang) => lang.id === id)
    if (language) {
      selectedLanguages.push({ label: language.language, value: language.id })
    }
  })
  return selectedLanguages
}

const findCategoriesByIds = (ids: string, categories?: Category[]) => {
  if (!ids) return []
  if (!categories) return []
  const categoryIds = ids.split(',')
  const selectedCategories: SelectField[] = []
  categoryIds.forEach((id) => {
    const category = categories.find((cat) => cat.categoryId === id)
    if (category) {
      selectedCategories.push({
        label: category.categoryName,
        value: category.categoryId,
      })
    }
  })

  return selectedCategories
}

interface SelectField {
  label: string
  value: string
}

export interface VideoDetailsProps {
  title?: string
  description?: string
  category?: SelectField[]
  language?: SelectField[]
  subtitlesRequested?: boolean
  schedulingChoice?: 'publish_now' | 'schedule'
  scheduleDateTime?: string
  isMadeForKids?: string
  isRestrictAge?: string
}

export const Information = ({
  progress,
  transcoding,
  transcodingComplete,
  generatedThumbnails,
  setThumbnail,
  playbackURL,
  selectedThumbnail,
  uploadedThumbnail,
  setUploadedThumbnail,
  fileToken,
  uploadedThumbnailFile,
  setUploadedThumbnailFile,
  onClose,
  resetUpload,
  videoDuration,
  transferToS3Completed,
  tabIndex,
  setTabIndex,
  setFinalData,
  draftData,
  openPublishedModal,
}: {
  progress: string
  transcoding: boolean
  setFinalData: (data: FinalDataInterface) => void
  transcodingComplete: boolean
  generatedThumbnails: Array<string>
  setThumbnail: (thumb: number) => void
  selectedThumbnail: number
  uploadedThumbnail: string
  playbackURL: string
  transferToS3Completed: boolean
  setUploadedThumbnail: (thumb: string) => void
  fileToken: string
  setUploadedThumbnailFile: (thumb: File | null) => void
  uploadedThumbnailFile: File | null
  onClose: () => void
  resetUpload: () => void
  videoDuration: number
  tabIndex: number
  setTabIndex: (tabIndex: number) => void
  draftData?: DraftData
  openPublishedModal: () => void
}) => {
  const detailsPanelRef = useRef<HTMLDivElement>(null)
  const publishPanelRef = useRef<HTMLDivElement>(null)
  const modalBodyRef = useRef<HTMLDivElement>(null)
  const { user } = useUser()
  const params = useParams() as { id: string }
  const queryClient = useQueryClient()
  const languages = useLanguagesQuery().data
  const categories = useCategoriesQuery().data

  const draftLang = draftData ? draftData.languageIds : ''
  const videoLanguages = findLanguagesByIds(draftLang, languages)

  const draftCategory = draftData ? draftData.categoryIds : ''
  const videoCategories = findCategoriesByIds(draftCategory, categories)
  const methods = useForm<VideoDetailsProps>({
    shouldFocusError: false,
    defaultValues: {
      schedulingChoice: 'publish_now',
    },
    values: {
      ...draftData,
      language: videoLanguages,
      category: videoCategories,
    },
  })
  const {
    register,
    setError,
    control,
    trigger,
    getValues,
    watch,
    formState: { errors, isValid },
  } = methods

  const [thumbnailError, setThumbnailError] = useState(false)
  const thumbnailErrorRef = useRef<HTMLDivElement>(null)
  const [uploadedThumbURL, setUploadedThumbURL] = useState('')
  const [count, setCount] = useState({ title: 0, description: 0 })

  const saveVideo = useSaveVideoDetailsMutation({
    draftMode: !!(draftData || getValues('schedulingChoice') == 'schedule'),
  })
  const publishVideo = usePublishVideoMutation({
    draftMode: !!(draftData || getValues('schedulingChoice') == 'schedule'),
  })
  const generateSubtitles = useGenerateSubtitlesMutation()
  const router = useRouter()

  const [canFocus, setCanFocus] = useState(true)

  const onError = () => {
    setCanFocus(true)
  }

  useEffect(() => {
    if (methods.formState.errors && canFocus) {
      const elements = Object.keys(methods.formState.errors)
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el)
      elements.sort(
        (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
      )

      if (elements.length > 0) {
        const errorElement = elements[0]
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        errorElement.focus({ preventScroll: true })
        setCanFocus(false)
      }
    }
  }, [methods.formState, canFocus])

  const focusThumnbailError = (focus: boolean) => {
    if (focus) {
      thumbnailErrorRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      setThumbnailError(true)
    } else {
      setThumbnailError(false)
    }
  }

  const getSchedulingDetails = () => {
    let schedule_status: '0' | '1' = '0'
    let schedule_date = ''
    let schedule_time = ''
    if (
      getValues('schedulingChoice') == 'schedule' &&
      getValues('scheduleDateTime')
    ) {
      schedule_status = '1'
      let scheduleDateTime = getValues('scheduleDateTime') ?? ''
      scheduleDateTime = new Date(scheduleDateTime).toISOString()
      schedule_date = scheduleDateTime.split('T')[0]
      schedule_time = scheduleDateTime.split('T')[1].split('.')[0]
    }
    return {
      schedule_status,
      schedule_date,
      schedule_time,
    }
  }
  const onSubmit = async () => {
    if (tabIndex == 0) {
      const isValid = await trigger()
      if (!isValid) {
        // toast.error('Please fill in all the required information')
        onError()
        return
      }
      if (!transcoding) {
        toast.error('Please let the video upload finish before proceeding')
        return
      }
      // if (selectedThumbnail < 0) {
      //   toast.error('Please select a thumbnail!')
      //   focusThumnbailError(true)
      //   return
      // } else {
      //   focusThumnbailError(false)
      // }

      saveVideo.mutate(
        {
          fileToken: fileToken,
          title: getValues('title') || '',
          description: getValues('description') || '',
          subtitlesRequested: getValues('subtitlesRequested') ? '1' : '0',
          thumbnailUrl:
            selectedThumbnail == 4 &&
              (uploadedThumbnailFile || uploadedThumbURL)
              ? uploadedThumbnailFile || uploadedThumbURL
              : selectedThumbnail !== -1
                ? generatedThumbnails[selectedThumbnail]
                : '',
          categoryIds:
            getValues('category')
              ?.map((cat) => cat.value)
              .join(',') || '',
          languageIds:
            getValues('language')
              ?.map((lang) => lang.value)
              .join(',') || '',
          uploadThumbnail:
            selectedThumbnail == 4 && !uploadedThumbURL ? true : false,
          visibility: '0',
          isMadeForKids: getValues('isMadeForKids'),
          isRestrictAge: getValues('isRestrictAge') ? '1' : '0',
          ...getSchedulingDetails(),
        },
        {
          onSuccess: (data) => {
            if (selectedThumbnail == 4 && uploadedThumbnailFile) {
              setUploadedThumbURL(data.thumbnailUrl as string)
              setUploadedThumbnailFile(null)
              setUploadedThumbnail('')
            }
            setTabIndex(1)
            toast.success('Video details saved!')
            modalBodyRef.current?.scroll({
              top: 0,
              behavior: 'smooth',
            })
          },
          onError: (error) => {
            toast.error(error.message)
          },
        }
      )
    }
    if (tabIndex == 1) {
      // if (selectedThumbnail < 0) {
      //   toast.error('Please select a thumbnail!')
      //   focusThumnbailError(true)
      //   return
      // } else {
      //   focusThumnbailError(false)
      // }
      // if (!transcodingComplete && !(getValues('schedulingChoice') == 'schedule')) {
      //   toast.error('Please wait while the video is transcoding')
      //   return
      // }
      // if (!transferToS3Completed && !(getValues('schedulingChoice') == 'schedule')) {
      //   toast.error('Please wait while the video is being processed')
      //   return
      // }

      saveVideo.mutate(
        {
          fileToken: fileToken,
          title: getValues('title') || '',
          description: getValues('description') || '',
          subtitlesRequested: getValues('subtitlesRequested') ? '1' : '0',
          thumbnailUrl:
            selectedThumbnail == 4 &&
              (uploadedThumbnailFile || uploadedThumbURL)
              ? uploadedThumbnailFile || uploadedThumbURL
              : selectedThumbnail !== -1
                ? generatedThumbnails[selectedThumbnail]
                : '',
          categoryIds:
            getValues('category')
              ?.map((cat) => cat.value)
              .join(',') || '',
          languageIds:
            getValues('language')
              ?.map((lang) => lang.value)
              .join(',') || '',
          uploadThumbnail:
            selectedThumbnail == 4 && uploadedThumbnailFile ? true : false,
          visibility: '0',
          isMadeForKids: getValues('isMadeForKids'),
          isRestrictAge: getValues('isRestrictAge') ? '1' : '0',
          ...getSchedulingDetails(),
        },
        {
          onSuccess: () => {
            if (getValues('subtitlesRequested')) {
              generateSubtitles.mutate(fileToken, {
                onSuccess: () => { },
                onError: (error) => {
                  toast.error(error.message)
                },
              })
            }
            publishVideo.mutate(
              {
                fileToken: fileToken,
              },
              {
                onSuccess: (data) => {
                  setFinalData({
                    duration: parseInt(data.duration),
                    thumbnail: data.thumbnailImage,
                    title: data.eventTitle,
                    url: 'https://lykstage.com' + data.encryptVideoUrl,
                    scheduled: getValues('scheduleDateTime')
                      ? new Date(
                        getValues('scheduleDateTime') || ''
                      ).toISOString()
                      : '',
                    cdnTranscode: data.cdnTranscode,
                  })
                  resetUpload()
                  onClose()
                  openPublishedModal()
                  if (data.cdnTranscode !== '0')
                    toast.success('Video published successfully!')
                },
                onError: (error) => {
                  toast.error(error.message)
                },
              }
            )
          },
          onError: (error) => {
            toast.error(error.message)
          },
        }
      )
    }
  }

  const schedulingChoice = watch('schedulingChoice')
  function getIsoDateStringWith7DaysAdded() {
    const date = new Date() // current date and time
    date.setDate(date.getDate() + 7) // add 7 days
    return format(date, 'yyyy-MM-dd HH:mm')
  }
  function getIsoDateStringWith6hoursAdded() {
    const date = new Date() // current date and time
    date.setHours(date.getHours() + 6) // add 6 hours
    return format(date, 'yyyy-MM-dd HH:mm')
  }
  return (
    <>
      <ModalBody pb={0} px={0} pt={0} ref={modalBodyRef} overflowX="hidden">
        <>
          <Tabs
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
            pt={0}
          >
            <TabList pos="sticky" top={0} px={[2, 2, 4]} bg="white" zIndex={2}>
              {/* <Tab>Basic info</Tab> */}
              <Tab color="#24BFA3" fontWeight="500">
                Video details
              </Tab>
              <Tab isDisabled={tabIndex == 0} color="#24BFA3" fontWeight="500">
                {schedulingChoice == 'schedule' ? 'Finish' : 'Publish'}
              </Tab>
            </TabList>

            <TabPanels px={[2, 2, 4]}>
              <TabPanel ref={detailsPanelRef}>
                <FormProvider {...methods}>
                  <form className="mb-10" name="video-details">
                    <FormControl mb={5} isRequired isInvalid={!!errors.title}>
                      <FormLabel {...labelStyles}>Title</FormLabel>

                      <Input
                        {...inputStyles}
                        placeholder="Enter title here"
                        type="text"
                        {...register('title', {
                          required: 'Title is required',
                          minLength: {
                            message: 'Title must be at least 5 characters',
                            value: 5,
                          },
                          maxLength: {
                            message: 'Title too long. Keep it under 100 characters.',
                            value: 100,
                          },
                        })}
                        onChange={(e) => setCount({ ...count, title: String(e.target.value).length })}
                      />
                      <span style={{ color: count.title > 100 ? "#ed837f" : "#3E3E3E", fontSize: 14, textAlign: 'right', display: 'block' }}>{count.title}/100</span>
                      <FormErrorMessage style={{ marginTop: "-18px" }}>
                        <>{errors.title && errors.title.message}</>
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mb={5}
                      isRequired
                      isInvalid={!!errors.description}
                    >
                      <FormLabel {...labelStyles}>Description</FormLabel>
                      <Textarea
                        placeholder="Enter video description"
                        minH={40}
                        maxH={80}
                        {...inputStyles}
                        {...register('description', {
                          required: 'Description is required',
                          maxLength: {
                            message: 'Description is too long. Keep it under 5000 characters.',
                            value: 5000,
                          },
                        })}
                        onChange={(e) => setCount({ ...count, description: String(e.target.value).length })}
                      />
                      <span style={{ color: count.description > 5000 ? "#ed837f" : "#3E3E3E", fontSize: 14, textAlign: 'right', display: 'block' }}>{count.description}/5000</span>

                      <FormErrorMessage style={{ marginTop: "-18px" }}>
                        <>{errors.description && errors.description.message}</>
                      </FormErrorMessage>
                    </FormControl>

                    <Box mb={5}>
                      <Thumbnails
                        setThumbnail={setThumbnail}
                        generatedThumbnails={generatedThumbnails}
                        selectedThumbnail={selectedThumbnail}
                        uploadedThumbnail={uploadedThumbnail}
                        setUploadedThumbnail={setUploadedThumbnail}
                        setUploadedThumbnailFile={setUploadedThumbnailFile}
                        uploadedThumbnailURL={uploadedThumbURL}
                        setUploadedThumbURL={setUploadedThumbURL}
                      />
                      <Text fontSize={14} opacity={0.7} fontWeight={400} mt={2}>
                        You can upload a custom thumbnail or choose from the
                        generated ones.
                      </Text>
                      {thumbnailError && (
                        <Text
                          fontSize={14}
                          opacity={1}
                          fontWeight={400}
                          ref={thumbnailErrorRef}
                          mt={2}
                          color={'#E53E3E'}
                        >
                          Thumnbail is required
                        </Text>
                      )}
                    </Box>

                    {/* <FormControl mb={5} isInvalid={!!errors.subtitlesRequested}>
                      <Checkbox
                        colorScheme="green"
                        {...labelStyles}
                        {...register('subtitlesRequested')}
                      >
                        Generate automatic subtitles
                      </Checkbox>
                      <Text fontSize={14} opacity={0.7} fontWeight={400}>
                        This is currently only supported for English language
                        videos.
                      </Text>
                      <FormErrorMessage>
                        <>
                          {errors.subtitlesRequested &&
                            errors.subtitlesRequested.message}
                        </>
                      </FormErrorMessage>
                    </FormControl> */}

                    {/* <Box mb={3}>
                      <Text mb={2}>Select video visibility</Text>
                      <VisibilityType
                        value={vid_type}
                        handleChange={handlevid_type}
                      />
                    </Box> */}

                    {/* <Box mb={3}>
                <Text mb={2}>Select video type*</Text>
                <VideoType value={vid_type} handleChange={handlevid_type} />
              </Box>

              <Box>
                <Text mb={2}>Scheduling*</Text>
                <Scheduling
                  value={scheduling_val}
                  handleChange={handlescheduling_val}
                />
              </Box> */}

                    <Box mb={5}>
                      <Scheduling
                        control={control as unknown as Control<FieldValues>}
                        name="schedulingChoice"
                        id="schedulingChoice"
                        label="Scheduling"
                      />
                    </Box>
                    {schedulingChoice == 'schedule' ? (
                      <>
                        <FormControl
                          mb={5}
                          isInvalid={!!errors.scheduleDateTime}
                          isRequired={true}
                        >
                          <FormLabel {...labelStyles}>Date and Time</FormLabel>
                          <Input
                            placeholder="Select Date and Time"
                            type="datetime-local"
                            min={getIsoDateStringWith6hoursAdded()}
                            max={getIsoDateStringWith7DaysAdded()}
                            {...inputStyles}
                            {...register('scheduleDateTime', {
                              required: 'Date and Time is required',
                              min: {
                                value: getIsoDateStringWith6hoursAdded(),
                                message:
                                  'Scheduling time should be minimum 6 hours from now',
                              },
                              max: {
                                value: getIsoDateStringWith7DaysAdded(),
                                message:
                                  'Date and Time should not be greater than 7 days',
                              },
                            })}
                          />
                          <FormErrorMessage>
                            <>
                              {errors.scheduleDateTime &&
                                errors.scheduleDateTime.message}
                            </>
                          </FormErrorMessage>
                        </FormControl>
                      </>
                    ) : null}

                    <Box mb={5}>
                      <ControlledSelect
                        control={control as unknown as Control<FieldValues>}
                        isMulti={true}
                        isClearable={true}
                        name="category"
                        id="category"
                        rules={{
                          required: 'Category is required',
                        }}
                        placeholder="Choose categories"
                        label="Categories"
                        isRequired={true}
                        options={
                          categories
                            ? categories.map((c) => {
                              return {
                                label: c.categoryName,
                                value: c.categoryId,
                              }
                            })
                            : []
                        }
                      />
                    </Box>
                    <Box mb={5}>
                      <FormControl isRequired={true}>
                        <FormLabel {...labelStyles} >
                          Audience, Is it made for kids
                        </FormLabel>
                        <RadioGroup>
                          <Stack gap={3} direction="column">
                            <Radio
                              {...register('isMadeForKids', { required: 'This field is required' })}
                              value={'1'}
                              size={['sm', 'sm', 'md']}
                              colorScheme='green'
                            >
                              <Text fontSize="sm">Yes, it’s made for kids</Text>
                            </Radio>
                            <Radio
                              {...register('isMadeForKids', { required: 'This field is required' })}
                              value={'0'}
                              size={['sm', 'sm', 'md']}
                              colorScheme='green'
                            >
                              <Text fontSize="sm">No, it’s not made for kids</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                        {errors.isMadeForKids && <Text color="red.500">{errors.isMadeForKids.message}</Text>}
                      </FormControl>

                    </Box>
                    <Box mb={5}>
                      <FormControl>
                        <Checkbox
                          {...register('isRestrictAge')}
                          colorScheme="green"
                          size={{ base: 'sm', md: 'md' }}
                        >
                          Restrict for users under the age of 18
                        </Checkbox>
                      </FormControl>
                    </Box>
                    <Box mb={5}>
                      <ControlledSelect
                        control={control as unknown as Control<FieldValues>}
                        isMulti={true}
                        isClearable={true}
                        name="language"
                        id="language"
                        placeholder="Choose your languages"
                        label="Language"
                        isRequired={true}
                        rules={{
                          required: 'Language is required',
                        }}
                        options={
                          languages
                            ? languages.map((language) => {
                              return {
                                label: language.language,
                                value: language.id,
                              }
                            })
                            : []
                        }
                      />
                    </Box>

                    {/* <FormControl>
                        <Text
                          fontWeight={500}
                          fontSize={'14px'}
                          opacity={0.7}
                          mb={2}
                        >
                          Tags (Separated by commas)
                        </Text>
                        <Input placeholder="Enter comma separated tags" />
                      </FormControl> */}

                    {/* <br />
              <br />
              <br />
              <br />
              <br /> */}

                    {/* {vid_type == 'premium' ? (
                <>
                  <Box mb={3}>
                    <Text
                      fontWeight={500}
                      fontSize={'14px'}
                      opacity={0.7}
                      mb={2}
                    >
                      Select Country*
                    </Text>
                    <Select placeholder="Choose your country"></Select>
                  </Box>

                  <Flex mb={7} gap={3} align={'center'}>
                    <Image w={'30px'} alt="Info" src="/upload/region.svg" />
                    <Text fontWeight={400} fontSize={'14px'} opacity={0.5}>
                      Please select the region where you want to avail the
                      video. Prices will be displayed according to your
                      selection.
                    </Text>
                  </Flex>

                  <Box mb={3}>
                    <Text
                      fontWeight={500}
                      fontSize={'14px'}
                      opacity={0.7}
                      mb={2}
                    >
                      Global price*
                    </Text>
                    <Select />
                  </Box>

                  <Box>
                    <Text
                      fontWeight={500}
                      fontSize={'14px'}
                      opacity={0.7}
                      mb={2}
                    >
                      India price*
                    </Text>
                    <Select />
                  </Box>
                  <Checkbox mt={2} mb={3} defaultChecked>
                    <Text fontWeight={500} fontSize={'14px'} opacity={0.7}>
                      Same as global price.
                    </Text>
                  </Checkbox>

                  <Flex mb={7} gap={3} align={'center'}>
                    <Image w={'30px'} alt="Info" src="/upload/region.svg" />
                    <Text fontWeight={400} fontSize={'14px'} opacity={0.5}>
                      Prices for India will be in INR, while prices for the rest
                      of the world will be in US$.
                    </Text>
                  </Flex>

                  <Text
                    fontStyle={'italic'}
                    fontWeight={400}
                    fontSize={'12px'}
                    opacity={0.5}
                  >
                    In the Premium Play section, viewers can access paid videos.
                    Creators earn 75% of the net proceeds from rental of premium
                    videos, adjusting for any applicable taxes and fees.
                  </Text>
                </>
              ) : null}

              {vid_type == 'passwordprotected' ? (
                <>
                  <Box mb={3}>
                    <Text
                      fontWeight={500}
                      fontSize={'14px'}
                      opacity={0.7}
                      mb={2}
                    >
                      Password*
                    </Text>
                    <Flex>
                      <Input type="text" placeholder="Enter a password"></Input>
                      <Button
                        _hover={{ opacity: 0.7 }}
                        color={'white'}
                        bg={'lyk-green'}
                        fontSize={14}
                        fontWeight={500}
                        px={10}
                        leftIcon={<Icon as={RxReload} />}
                      >
                        Reset
                      </Button>
                    </Flex>
                  </Box>
                </>
              ) : null} */}
                  </form>
                </FormProvider>
              </TabPanel>

              <TabPanel ref={publishPanelRef}>
                {transferToS3Completed ? (
                  <Box
                    width={'100%'}
                    aspectRatio={16 / 9}
                    mb={5}
                    rounded={'md'}
                    overflow="hidden"
                  >
                    <PreviewPlayerWrapper playbackURL={playbackURL} />
                  </Box>
                ) : (
                  <Box
                    width={'100%'}
                    aspectRatio={16 / 9}
                    border={'solid'}
                    borderColor={'#eaeaea'}
                    borderWidth={3}
                    bg={'#eaeaea'}
                    mb={5}
                    rounded={'md'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDir="column"
                    padding={'1rem'}
                  >
                    <Image
                      src="/upload/generating-preview.svg"
                      alt="Generating preview"
                      width={'22%'}
                    />
                    <Text
                      color="lyk-green"
                      fontSize={['sm', 'sm', 'md', 'xl']}
                      mt={4}
                      width="80%"
                      textAlign="center"
                      fontWeight="500"
                    >
                      Tap ‘finish to close window. The transcoding will happen
                      in the background.
                    </Text>
                    <Text
                      fontSize={['sm', 'sm', 'md']}
                      mt={2}
                      color={'#2A2A2AB2'}
                      textAlign={'center'}
                    >
                      Video will appear under In progress in the{' '}
                      <Link
                        color={'#318FD2'}
                        href={'/c/' + user?.channelShareName}
                      >
                        My Channel
                      </Link>{' '}
                      page.
                    </Text>
                    <Text
                      fontSize={['sm', 'sm', 'md']}
                      mt={4}
                      color={'#2A2A2AB2'}
                      textAlign={'center'}
                    >
                      Preview will be available after video has finished
                      transcoding...
                    </Text>
                  </Box>
                )}
                <Thumbnails
                  setThumbnail={setThumbnail}
                  generatedThumbnails={generatedThumbnails}
                  selectedThumbnail={selectedThumbnail}
                  uploadedThumbnail={uploadedThumbnail}
                  setUploadedThumbnail={setUploadedThumbnail}
                  setUploadedThumbnailFile={setUploadedThumbnailFile}
                  uploadedThumbnailURL={uploadedThumbURL}
                  setUploadedThumbURL={setUploadedThumbURL}
                />
                {thumbnailError && (
                  <Text
                    fontSize={14}
                    opacity={1}
                    fontWeight={400}
                    ref={thumbnailErrorRef}
                    mt={2}
                    color={'#E53E3E'}
                  >
                    Thumnbail is required
                  </Text>
                )}
                {!transferToS3Completed ? (
                  <Text mt={2}>
                    <Checkbox
                      defaultChecked
                      size={{ base: 'sm', md: 'md' }}
                      isChecked={true}
                      colorScheme="teal"
                      cursor={'default'}
                    >
                      Auto publish video when transcoding is complete
                    </Checkbox>
                  </Text>
                ) : (
                  ''
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      </ModalBody>
      <ModalFooter
        className="border-gray-500 z-[100] border-t-[2px]"
        py={4}
        bg={'white'}
        w={'100%'}
      >
        <Flex
          px={[2, 2, 4]}
          w="100%"
          gap={[3, 3, 10]}
          justify={'space-between'}
          align={['flex-start', 'flex-start', 'center']}
          flexDir={['column', 'column', 'row']}
        >
          <Box flex={1} w="100%">
            <ProgressBar
              status={transcoding ? 'transcoding' : 'uploading'}
              progress={
                transcoding
                  ? transcodingComplete && transferToS3Completed
                    ? 100
                    : 1
                  : parseInt(progress)
              }
            />
          </Box>

          <Flex w={['100%', '100%', 'auto']} justifyContent={'flex-end'}>
            <Flex flex={1} gap={2}>
              {tabIndex == 1 && schedulingChoice !== 'schedule' ? (
                <Button
                  flex={1}
                  variant="ghost"
                  color={'lyk-green'}
                  fontSize={{
                    base: 'sm',
                    md: 'md',
                  }}
                  onClick={() => {
                    onClose()
                    resetUpload()
                    queryClient.invalidateQueries({
                      queryKey: ['draft-videos'],
                    })
                    router.push(`/c/${user?.channelShareName}?tab=drafts`)
                  }}
                >
                  Save Draft
                </Button>
              ) : (
                <></>
              )}
              <Button
                isLoading={saveVideo.isPending || publishVideo.isPending}
                flex={1}
                bg={'lyk-green'}
                color={'white'}
                opacity={!transcoding ? 0.4 : 1}
                _hover={{ opacity: !transcoding ? 0.4 : 0.7 }}
                onClick={onSubmit}
              >
                {tabIndex == 0
                  ? 'Next'
                  : schedulingChoice == 'schedule' || !transferToS3Completed
                    ? 'Finish'
                    : 'Publish'}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </ModalFooter>
    </>
  )
}